import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@atoms/Checkbox';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { theme } from '@theme';
import colors from '@theme/colors';
import { noop } from '@utils/noop';

import { Input } from '../Input';
import { SelectProps } from './definitions';

const Select = ({
  className: classNameFromProps = '',
  value,
  options,
  onChange,
  onBottomReached,
  disabled,
  variant = 'default',
  searchValue,
  onSearchChange = noop,
  placeholder,
  error,
  bold,
  multiple,
  clearSearchOnClose,
}: SelectProps) => {
  const { t } = useTranslation();

  const listRef = useRef<HTMLDivElement>(null);

  const selectRef = useRef<HTMLDivElement>(null);

  const [isSelectListOpen, setIsSelectListOpen] = useState(false);

  const hasError = useMemo(() => Boolean(error), [error]);

  const isSearchVariant = useMemo(() => variant === 'search', [variant]);

  const valueIsSelected = useMemo(() => {
    if (multiple) {
      return value.length > 0;
    }

    return value !== '';
  }, [multiple, value]);

  useEffect(() => {
    if (clearSearchOnClose && !isSelectListOpen) {
      onSearchChange('');
    }
  }, [clearSearchOnClose, isSelectListOpen, onSearchChange]);

  // Function to get the label for an option, with styling based on whether the option is disabled
  const getOptionLabel = useCallback((label: string, disabled?: boolean) => {
    return (
      <Typography
        size="sm"
        sizeMd="md"
        className={disabled ? 'text-Primary-03' : 'text-Primary-00'} // Conditional styling based on disabled state
      >
        {label}
      </Typography>
    );
  }, []);

  // Function to handle selecting an item from the list
  const handleSelectListItem = useCallback(
    (selectedValue: string) => {
      if (multiple) {
        if (value.includes(selectedValue)) {
          const newValue = value.filter((val) => val !== selectedValue);
          onChange(newValue);
        } else {
          const newValue = [...value, selectedValue];
          onChange(newValue);
        }
      } else {
        onChange(selectedValue);
      }

      if (multiple !== true) {
        setIsSelectListOpen(false);
      }

      if (isSearchVariant) {
        onSearchChange('');
      }
    },
    [isSearchVariant, multiple, onChange, onSearchChange, value],
  );

  const displayValue = useMemo(() => {
    if (multiple) {
      return value
        .map((val) => {
          const selectedOption = options.find((opt) => opt.value === val);
          return selectedOption?.label;
        })
        .filter(Boolean)
        .join(',');
    }

    const selectedOption = options.find((opt) => opt.value === value);
    return selectedOption?.label;
  }, [multiple, options, value]);

  // Effect to handle clicks outside the select, closing the select list
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isSelectListOpen === true &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsSelectListOpen(false);
      }
    };

    // Add the event listener for mousedown
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSelectListOpen, multiple]);

  const handleSelectClick = useCallback(() => {
    !disabled && setIsSelectListOpen(!isSelectListOpen);
  }, [disabled, isSelectListOpen]);

  const handleListScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        onBottomReached?.();
      }
    }
  }, [onBottomReached]);

  useEffect(() => {
    listRef.current?.addEventListener('scroll', handleListScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      listRef.current?.removeEventListener('scroll', handleListScroll);
    };
  }, [handleListScroll, listRef, isSelectListOpen]);

  const calculatedIcon = useMemo(() => {
    if (isSearchVariant && valueIsSelected) {
      return (
        <IconSVG
          icon="close-circle"
          size={18}
          color={colors['Primary-03']}
          onClick={() => {
            if (multiple) {
              onChange([]);
            } else {
              onChange('');
            }
          }}
        />
      );
    }

    return (
      <IconSVG
        icon={isSelectListOpen ? 'chevron-up' : 'chevron-down'}
        size={20}
        color={`${disabled ? theme.colors['Primary-03'] : theme.colors['Primary-00']}`}
        onClick={handleSelectClick}
      />
    );
  }, [
    disabled,
    handleSelectClick,
    isSearchVariant,
    isSelectListOpen,
    multiple,
    onChange,
    valueIsSelected,
  ]);

  const displayValueClassName = useMemo(() => {
    const classes = ['text-sm md:text-md '];

    if (disabled) {
      classes.push('text-Primary-03');
    } else {
      classes.push('text-Primary-00');
    }

    if (bold) {
      classes.push('font-bold');
    } else {
      classes.push('font-normal');
    }

    return classes.join(' ');
  }, [bold, disabled]);

  return (
    <div className={classNameFromProps}>
      <div
        className={`${disabled ? 'border-Primary-03' : hasError ? 'border-System-red' : 'border-Primary-00'} ui-dropdown border-b w-full`}
        ref={selectRef}
      >
        <div
          tabIndex={0}
          className={`h-[40px] flex items-center justify-start gap-2 ${!isSearchVariant ? 'cursor-pointer' : ''}`}
          // if is search variant, allow click only on chevron icon
          onClick={isSearchVariant ? undefined : handleSelectClick}
        >
          <div className="flex-1">
            {isSearchVariant && !valueIsSelected ? (
              <Input
                className="border-none bg-Primary-05"
                name="search"
                value={searchValue}
                placeholder={placeholder}
                onChange={onSearchChange}
                autoComplete="off"
                onFocus={() => setIsSelectListOpen(true)}
                disabled={disabled}
              />
            ) : displayValue ? (
              <Typography className={displayValueClassName}>
                {displayValue}
              </Typography>
            ) : (
              <Typography color="text-Primary-03" size="sm" sizeMd="md">
                {placeholder ?? ''}
              </Typography>
            )}
          </div>

          {calculatedIcon}
        </div>

        {isSelectListOpen && (
          <div
            ref={listRef}
            tabIndex={0}
            className="mt-1 ui-dropdown-content z-[1] bg-Primary-05 shadow-md max-h-[200px] flex flex-col overflow-y-auto whitespace-nowrap w-[100%] border border-Primary-03 border-t-0"
          >
            {options.length > 0 ? (
              <ul>
                {options.map(
                  (
                    {
                      label: optionLabel,
                      value: optionValue,
                      disabled: optionDisabled,
                    },
                    index,
                  ) => (
                    // Render a list item for each option
                    <li
                      key={index} // Unique key for each list item
                      className={`${index !== options.length - 1 ? 'border-b border-Primary-04' : ''}`} // Add bottom border to all items except the last one
                    >
                      {multiple ? ( // Check if multiple selection is allowed
                        // Render a label with a checkbox for multiple selection
                        <label className="w-full p-3 flex gap-2 items-center cursor-pointer">
                          <Checkbox
                            value={optionValue} // Value of the checkbox
                            name={optionValue} // Name of the checkbox
                            checked={value.includes(optionValue)} // Check if the option is selected
                            onChange={() => handleSelectListItem(optionValue)} // Handle change event
                            size="xs" // Size of the checkbox
                          />
                          {getOptionLabel(optionLabel)}
                          {/* Render the option label */}
                        </label>
                      ) : (
                        // Render a button for single selection
                        <button
                          type="button" // Button type
                          className="w-full text-left p-3 cursor-pointer hover:bg-Primary-04" // Styling for the button
                          onClick={() => {
                            handleSelectListItem(optionValue); // Handle click event
                          }}
                        >
                          {getOptionLabel(optionLabel, optionDisabled)}
                          {/* Render the option label with disabled state */}
                        </button>
                      )}
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <Typography
                size="sm"
                className="text-Primary-03 text-center py-4"
              >
                {t('General.noOptions')}
              </Typography>
            )}
          </div>
        )}
      </div>

      {hasError && (
        <Typography
          color="text-System-red"
          size="xs"
          sizeMd="sm"
          className="mt-1"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

// Export the Select component, wrapped in React.memo for performance optimization
export default React.memo(Select);
