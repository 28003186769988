import { useCallback, useMemo, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { useUserApi } from '@api/UserApi';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import { Organization } from '@definitions/Organization';
import { QueryKeys } from '@definitions/QueryKeys';
import { useLogger } from '@hooks/useLogger';
import { Select } from '@molecules/Form/Select';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import organizationImagePlaceholder from '@utils/organizationImagePlaceholder';

const UserDetails = () => {
  const { user } = useUserContext();
  const { logError } = useLogger();
  const queryClient = useQueryClient();

  const { changeOrganization: changeOrganizationApi } = useUserApi();
  const { getOrganizationType, getOrganizationList } = useOrganizationsApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters = apiSearchValue.length >= 3;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const {
    data: organizationListData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.ORGANIZATION_LIST, parsedApiSearchValue],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationList({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const { data: organizationType } = useQuery({
    queryKey: [
      QueryKeys.ORGANIZATIONS_TYPE,
      user?.organization.organizationTypeId,
    ],
    queryFn: () =>
      getOrganizationType({ id: user?.organization.organizationTypeId || 0 }),
  });

  const { mutate: callChangeOrganizationApi } = useMutation({
    mutationFn: changeOrganizationApi,
  });

  const isUserAdmin = useMemo(
    () => !!user?.roles.find((item) => item.id === 1),
    [user?.roles],
  );

  const hasMultipleOrganizations = useMemo(() => {
    if (isUserAdmin) {
      return true;
    }
    return user?.organizations && user?.organizations.length > 1;
  }, [isUserAdmin, user?.organizations]);

  const organizationsOptions = useMemo(() => {
    if (isUserAdmin) {
      return organizationListData?.pages
        .flatMap((pages) => pages.data)
        .map((page) => ({
          value: page.id.toString(),
          label: page.name,
        }));
    }

    return user?.organizations?.map((organization: Organization) => {
      return {
        value: `${organization.id}`,
        label: organization.name,
      };
    });
  }, [isUserAdmin, organizationListData, user?.organizations]);

  const selectedOrganization = useMemo(() => {
    return organizationsOptions?.find((item) => {
      return item.label === user?.organization.name;
    });
  }, [organizationsOptions, user?.organization.name]);

  const onChange = useCallback(
    (value: string) => {
      if (value === selectedOrganization?.value) {
        return;
      }
      callChangeOrganizationApi(
        {
          organizationId: Number(value),
        },
        {
          onSuccess: (data) => {
            data.roleId === user?.role.id
              ? queryClient.invalidateQueries()
              : window.location.reload();
          },
          onError: (error) => {
            console.error('API call failed with error:', error);
            logError(error);
          },
        },
      );
    },
    [
      callChangeOrganizationApi,
      logError,
      queryClient,
      selectedOrganization?.value,
      user?.role.id,
    ],
  );

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  const organizationSelect = useMemo(() => {
    if (hasMultipleOrganizations) {
      return (
        <div className="flex gap-4">
          <Select
            variant="search"
            className="w-full organization-select"
            value={''}
            onChange={onChange}
            options={organizationsOptions || []}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            onBottomReached={handleBottomReached}
            placeholder={user?.organization.name || ''}
            clearSearchOnClose={true}
          />
        </div>
      );
    }
    return (
      <div className="flex gap-4 border-Primary-00 border-b pb-2">
        <Typography size="md" color="text-Primary-03">
          {user?.organization?.name}
        </Typography>
      </div>
    );
  }, [
    handleBottomReached,
    hasMultipleOrganizations,
    onChange,
    organizationsOptions,
    searchValue,
    user?.organization.name,
  ]);

  return (
    <>
      <div className="flex gap-4">
        <div>
          <div className="flex items-center justify-center rounded-full w-11 h-11">
            <img
              src={
                organizationType?.image
                  ? organizationType.image
                  : organizationImagePlaceholder()
              }
              alt="User image"
              className="rounded-full"
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Typography size="md" isBold>
            {user?.firstName} {user?.lastName}
          </Typography>
        </div>
      </div>
      {organizationSelect}
    </>
  );
};
export default UserDetails;
