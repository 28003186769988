import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { QueryKeys } from '@definitions/QueryKeys';
import useWindow from '@hooks/useWindow';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { FilterCommonProps } from './definitions';

export const OrganizationIdFilter = ({
  value,
  onChange,
}: FilterCommonProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();
  const { getOrganizationList, getOrganization } = useOrganizationsApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters = apiSearchValue.length >= 3;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const { data: organization } = useQuery({
    queryKey: [QueryKeys.ORGANIZATION, { organizationId: Number(value) }],
    queryFn: () => getOrganization({ organizationId: Number(value) }),
    enabled: !!value,
  });

  const {
    data: organizations,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.ORGANIZATION_LIST, parsedApiSearchValue],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationList({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),
    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const organizationsOptions = useMemo(() => {
    return organizations?.pages
      .flatMap((pages) => pages.data)
      .map((page) => ({
        value: page.id.toString(),
        label: page.name,
      }));
  }, [organizations]);

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }
    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  const handleSelectClassName = useMemo(() => {
    if (value === '') {
      return '';
    }
    return 'dropdown-input-select';
  }, [value]);

  return (
    <FormField
      label={t('Filters.organizationId.label')}
      direction={isMobile ? 'row' : 'column'}
    >
      <Select
        variant="search"
        value={''}
        onChange={onChange}
        className={handleSelectClassName}
        placeholder={
          value === ''
            ? t('Filters.organizationId.placeholder')
            : organization?.name
        }
        options={organizationsOptions || []}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        onBottomReached={handleBottomReached}
      />
    </FormField>
  );
};
