// import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import { BottomSheet } from '@molecules/BottomSheet';
import { DateRangeFilter } from '@molecules/Filters/DateRangeFilter';
import { DirectionFilter } from '@molecules/Filters/Direction';
import { OrganizationIdFilter } from '@molecules/Filters/OrganizationId';
import { ProductIdFilter } from '@molecules/Filters/ProductId';
import { ShipmentInCourseIdFilter } from '@molecules/Filters/ShipmentInCourseId';

import {
  ShipmentsInCourseFiltersProps,
  ShipmentsInCourseFiltersRecord,
  ShipmentsInCourseListFilterKey,
} from './definitions';
import './styles.scss';

// Initial state for the filters
const FILTERS_INITIAL_STATE: ShipmentsInCourseFiltersRecord = {
  shipmentInCourseId: '',
  direction: 'from',
  organizationId: '',
  itemId: '',
  dates: '',
};

const ShipmentsInCourseFilters = ({
  onApply,
  onReset,
  isMobile,
  isMobileFiltersOpen,
  onCloseMobileFilters,
}: ShipmentsInCourseFiltersProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserContext();
  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);
  const [filtersKey, setFiltersKey] = useState<string>();

  // Reset all filters to their initial state
  const resetFilters = useCallback(() => {
    setFilters(FILTERS_INITIAL_STATE);
  }, []);

  // Apply the current filters
  // This function is called when the user clicks the "Apply" button.
  // It triggers the onApply callback with the current filter values.
  const handleApply = useCallback(() => {
    onApply(filters);
  }, [filters, onApply]);

  // Reset all filters and generate a new key to force re-render
  // This function is called when the user clicks the "Reset" button.
  // It resets all filters to their initial state, triggers the onReset callback,
  // and generates a new key to force the re-render of the filter components.
  const handleReset = useCallback(() => {
    resetFilters();
    onReset();
    setFiltersKey(crypto.randomUUID());
  }, [onReset, resetFilters]);

  // Reset a single filter field to its initial state
  // This function is called to reset a specific filter field to its initial state.
  // It updates the state of the filters, triggers the onReset callback,
  // and generates a new key to force the re-render of the filter components.
  const handleResetSingleField = useCallback(
    (field: keyof ShipmentsInCourseFiltersRecord) => {
      setFilters((prev) => ({
        ...prev,
        [field]: FILTERS_INITIAL_STATE[field],
      }));
      onReset();
      setFiltersKey(crypto.randomUUID());
    },
    [onReset],
  );

  // Set a filter value or reset it if it already has a value
  // This function is called to set the value of a specific filter field.
  // If the filter field already has a value, it resets the field to its initial state.
  // Otherwise, it updates the state of the filters with the new value.
  const setFilter = useCallback(
    (key: ShipmentsInCourseListFilterKey) => (value: string | undefined) => {
      if (filters[key] === '') {
        setFilters((prev) => ({
          ...prev,
          [key]: value,
        }));
      } else {
        handleResetSingleField(key);
      }
    },
    [filters, handleResetSingleField],
  );

  // Determine if the apply button should be disabled
  const isApplyButtonDisabled = useMemo(() => {
    if (
      !filters.shipmentInCourseId &&
      !filters.organizationId &&
      !filters.itemId &&
      !filters.dates
    ) {
      return true;
    }
  }, [filters]);

  // Determine if the reset button should be disabled
  const isResetButtonDisabled = isApplyButtonDisabled;

  // Handle changes to the date range filter
  const handleDateRangeFilterChange = useCallback(
    (value: Array<string | null>) => {
      const parsedValue = value.filter(Boolean);

      setFilter('dates')(
        parsedValue.length > 0 ? parsedValue.join(';') : undefined,
      );
    },
    [setFilter],
  );

  // Memoized node for rendering filters
  const FiltersNode = useMemo(() => {
    const dateRangeFilterValue = (
      filters.dates ? filters.dates.split(';') : [null, null]
    ) as [string | null, string | null];

    return (
      <Fragment key={filtersKey}>
        <ShipmentInCourseIdFilter
          value={filters.shipmentInCourseId}
          onChange={setFilter('shipmentInCourseId')}
        />

        {isAdmin && (
          <>
            <DirectionFilter
              value={filters.direction}
              onChange={setFilter('direction')}
            />

            <OrganizationIdFilter
              value={filters.organizationId}
              onChange={setFilter('organizationId')}
            />
          </>
        )}

        <ProductIdFilter
          value={filters.itemId}
          onChange={setFilter('itemId')}
        />

        <DateRangeFilter
          value={dateRangeFilterValue}
          onChange={handleDateRangeFilterChange}
          anchor={isMobile ? 'bottom' : 'top'}
        />
      </Fragment>
    );
  }, [
    filters,
    filtersKey,
    handleDateRangeFilterChange,
    isAdmin,
    isMobile,
    setFilter,
  ]);

  // Memoized node for rendering buttons
  const ButtonsNode = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <Button
          type="primary"
          label={t('General.apply')}
          onClick={handleApply}
          disabled={isApplyButtonDisabled}
        />
        <Button
          type="secondary"
          label={t('General.clearAll')}
          onClick={handleReset}
          disabled={isResetButtonDisabled}
        />
      </div>
    );
  }, [
    t,
    handleApply,
    handleReset,
    isApplyButtonDisabled,
    isResetButtonDisabled,
  ]);

  // Render for mobile view
  if (isMobile) {
    return (
      <BottomSheet
        isOpen={isMobileFiltersOpen}
        onClose={onCloseMobileFilters}
        detent="content-height"
      >
        <div className="p-4 flex flex-col justify-between h-full">
          <div>
            <Typography
              isUppercase
              isBold
              size="xl"
              className="text-center mb-6"
            >
              {t('Filters.insertFilters')}
            </Typography>
            <div className="flex flex-col gap-8 mb-10">{FiltersNode}</div>
          </div>

          {ButtonsNode}
        </div>
      </BottomSheet>
    );
  }

  // Render for desktop view
  return (
    <Card hasPadding={false} className="p-4">
      <div className="w-full flex flex-row gap-6 justify-between items-center">
        <div id="inCourseFiltersDesktop">{FiltersNode}</div>
        {ButtonsNode}
      </div>
    </Card>
  );
};

export default ShipmentsInCourseFilters;
